// Generate the required CSS
const generateStyleTagForFont = async (fontFile, fontName) => {
  const iconFontImport = await import(`react-native-vector-icons/Fonts/${fontFile}`)
  const iconFont = iconFontImport.default
  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: ${fontName};
  }`

  // Create a stylesheet
  const style = document.createElement("style")

  style.type = "text/css"

  // Append the iconFontStyles to the stylesheet
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles
  } else {
    style.appendChild(document.createTextNode(iconFontStyles))
  }

  // Inject the stylesheet into the document head
  document.head.appendChild(style)
}

generateStyleTagForFont("FontAwesome.ttf", "FontAwesome")
generateStyleTagForFont("FontAwesome5_Brands.ttf", "FontAwesome5_Brands")
generateStyleTagForFont("FontAwesome5_Regular.ttf", "FontAwesome5_Regular")
generateStyleTagForFont("FontAwesome5_Solid.ttf", "FontAwesome5_Solid")

generateStyleTagForFont("MaterialIcons.ttf", "MaterialIcons")
generateStyleTagForFont("MaterialCommunityIcons.ttf", "MaterialCommunityIcons")
