import {Modal} from "react-native"

const ComponentsLayoutOverlay = (props) => {
  const {children, className, ...restProps} = props
  const onRequestClose = useCallback(() => {
    // Do nothing
  }, [])

  return (
    <Modal onRequestClose={onRequestClose} transparent>
      <View
        dataSet={{class: classNames("components--layout--overlay", className)}}
        style={{
          position: "fixed",
          zIndex: 99997,
          width: "100vw",
          height: "100vh",
          background: "rgba(0 0 0 / 70%)",
          color: "#fff"
        }}
        {...restProps}
      >
        <View
          dataSet={{class: "overlay-container"}}
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {children}
        </View>
      </View>
    </Modal>
  )
}

ComponentsLayoutOverlay.propTypes = {
  children: PropTypes.node.isRequired
}

export default memo(ComponentsLayoutOverlay)
