import {Controller} from "stimulus"

export default class TrixFileAttachmentImageController extends Controller {
  connect() {
    console.log("TrixFileAttachmentImageController - removing width and height")

    this.element.removeAttribute("width")
    this.element.removeAttribute("height")
  }
}
