const Config = require("@kaspernj/api-maker/src/config").default
const I18nOnSteroids = require("i18n-on-steroids").default

const apiMakerFiles = require.context("../../../config/locales/api_maker", true, /^.\/([a-z.]+)\.yml$/)
const dateTimeFiles = require.context("../../../config/locales/awesome_translations/date_time", true, /^(.+)\.yml$/)
const hayaSelectFiles = require.context("../../../config/locales/haya_select", true, /^(.+)\.yml$/)
const i18n = new I18nOnSteroids({
  fallbacks: {
    da: ["da", "en"],
    en: ["en", "da"]
  }
})
const jsFiles = require.context("../../../config/locales/js", true, /^(.+)\.yml$/)
const awesomeTranslationsJsFiles = require.context("../../../config/locales/awesome_translations/js", true, /^(.+)\.yml$/)
const modelFiles = require.context("../../../config/locales/awesome_translations/models", true, /^(.+)\.yml$/)
const numbersFiles = require.context("../../../config/locales/awesome_translations/numbers", true, /^(.+)\.yml$/)
const routetTranslatorFiles = require.context("../../../config/locales/route_translator", true, /^(.+)\.yml$/)

i18n.scanRequireContext(apiMakerFiles)
i18n.scanRequireContext(awesomeTranslationsJsFiles)
i18n.scanRequireContext(dateTimeFiles)
i18n.scanRequireContext(hayaSelectFiles)
i18n.scanRequireContext(jsFiles)
i18n.scanRequireContext(modelFiles)
i18n.scanRequireContext(numbersFiles)
i18n.scanRequireContext(routetTranslatorFiles)

const htmlLang = document.querySelector("html").getAttribute("lang")
let locale

if (htmlLang) {
  const htmlLangMatch = htmlLang.match(/^([A-z]{2})(-|$)/)

  if (htmlLangMatch) {
    locale = htmlLangMatch[1]
  }
}

if (!locale) locale = "da"

i18n.setLocale(locale)
i18n.setLocaleOnStrftime()

Config.setI18n(i18n)

module.exports = i18n
